<template>
  <!-- form -->
  <validation-observer ref="formAuthPassword">
    <b-form>
      <b-row>
        <b-col md="12">
          <b-form-group label="Digite sua senha para autenticação" label-for="password">
            <validation-provider #default="{ errors }" name="Senha de autenticação" rules="required">
              <b-form-input
                id="password"
                v-model="dados.password"
                name="Senha"
                type="password"
                placeholder="Digite sua atual senha aqui"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" align="right">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="submit"
            variant="primary"
            @click="autenticar"
            :disabled="loading"
          >
            {{ !loading ? 'Autenticar' : '' }}
            <b-spinner small v-if="loading" />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        required,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        dados: {},
        loading: false,
      }
    },
    mounted() {},
    methods: {
      autenticar(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formAuthPassword.validate().then((success) => {
          if (success) {
            this.loading = true
            useJwt
              .verifyPassword({
                email: this.userData.email,
                password: this.dados.password,
              })
              .then((response) => {
                this.$emit('autenticarEvent', true)
                this.$emit('autenticado', true)
                this.$message.success('Autenticado com sucesso!')
              })
              .catch((error) => {
                this.dados.password = ''
                this.loading = false
                this.$emit('autenticarEvent', false)
                this.$emit('senhaInvalida', false)
                this.$message.error('Senha inválida!')
                console.error(error)
              })
          }
        })
      },
    },
  }
</script>
