<template>
  <div>
    <b-tabs pills>
      <b-tab title="Remessa" active>
        <b-card-text>
          <PagamentoRemessa ref="abaRemessa" />
        </b-card-text>
      </b-tab>
      <b-tab title="Retorno">
        <b-card-text>
          <PagamentoRetorno @atualizaAbaRemessa="atualizaAbaRemessa" />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import PagamentoRemessa from './Componentes/PagamentoRemessa.vue'
  import PagamentoRetorno from './Componentes/PagamentoRetorno'

  export default {
    components: {
      PagamentoRemessa,
      PagamentoRetorno,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
    methods: {
      atualizaAbaRemessa() {
        this.$refs.abaRemessa.carregarGrid()
      },
    },
  }
</script>
